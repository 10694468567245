/**
 * Render a tile per study.
 * Supported props: studies studyIntro localizerfn
 */

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Safe from './Safe';
import StudyTile from './StudyTile';
import { renderParagraphs } from '../util/rendertools';

export default function StudyDashboard(props) {
  const localized = props.localizerfn;
  const studies = props.studies.sort(compare);

  const studyImages = {
    dengueoralstudy: <StaticImage src="../images/dengueoralstudy_tile.jpg" alt="Dengue Oral Prevention or Treatment Study" />
  };

  return (
    <div>
      <h2 id="the-studies" className="text-pary up hpad-12p">{props.studyIntro.header}</h2>
      <div className="grid-cols-2 hpad-10p">
        {studies.map((item, index) => (
          <StudyTile key={item.slug}
                     config={item}
                     linklabel={item.studylink || props.studyIntro.studylink}
                     image={studyImages[item.slug]}
                     localizerfn={localized} />
        ))}
      </div>
    </div>
  );
};

// Compare the studies by "seq" field
function compare(a, b) {
  if (a.seq<b.seq) return -1;
  else if (a.seq>b.seq) return 1;
  else return 0;
}