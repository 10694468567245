/* Home page */

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/HomeLayout';
import PageHead from '../components/PageHead';
import HomeSection from '../components/HomeSection';

export default function Home(props) {
  const ctx = props.pageContext;
  const studies = props.data.localStudies.edges.map(study => study.node);
  const sitedata = props.data.homePage;

  return (
    <Layout config={sitedata} studies={studies} ctx={ctx} currentpath={props.path}>
      <PageHead lang={ctx.locale} sitename={sitedata.sitename} siteurl={sitedata.siteurl} meta={sitedata.metaDescription} />
      <HomeSection config={sitedata.sections} />
    </Layout>
  );
};

export const query = graphql`
  query indexQ($locale: String) {
    localStudies: allStudyYaml(filter: {locale: {eq: $locale}}) {
      edges {
        node {
          locale
          seq
          siteurl
          slug
          linkto
          title
          tiletitle
          description
          studylink
        }
      }
    }
    homePage: siteYaml(locale: {eq: $locale}) {
      locale
      sitename
      siteurl
      banner {
        title
        subtitle
        text
      }
      homelink
      metaDescription
      studyList {
        header
        studylink
      }
      links {
        id
        title
        linkto
      }
      sections {
        id
        title
        text
      }
      footer {
        links {
          label
          to
        }
        socialLinkLabel
        text
        theme
      }
      cookie {
        text
        linkText
        accept
        reject
      }
    }
  }`;
