/**
 * Banner provides a container with a title and image.
 * Supported props: title subtitle theme
 */
import React from 'react';
import Safe from './Safe';
import {renderParagraphs} from '../util/rendertools';

export default function Banner(props) {
  return (
    <div id="banner" className="banner-bg">
      <div className="banner-pad banner-v-pad">
        <em><Safe type="h1" content={props.title} /></em>
        <div className="banner-subtitle">{props.subtitle}</div>
        {renderParagraphs(props.text, 'banner-text')}
      </div>
    </div>
  );
};
