
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';    
import Safe from './Safe';
import {renderParagraphs} from '../util/rendertools';
import {css} from '../util/pagetools';

export default function HomeSection(props) {
  const config = props.config;

  return (
    <div id="sections" className="white-on-pary">
      {props.config.map((item, index) => {
        const vpad = (index===0 ? 'top-sec' : (index<(props.config.length-1) ? 'middle-sec' : 'bottom-sec'));
        return (
          <div id={item.id} key={`s-${index}`} className={css('homesection hpad-10p', vpad)}>
            <Safe className="fw-200" type="h2" content={item.title} />
            {renderParagraphs(item.text)}
          </div>
        )})}
      <StaticImage src='../images/ctximg_1.jpg' alt="" className="ctximg" />
    </div>
  );
};
