/**
 * HomeLayout defines the main page structure for the home page.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import Banner from './Banner';
import Nav from './NavToggleFlex';
import Footer from './Footer';
import StudyDashboard from './StudyDashboard';
import { CookieProvider } from './CookieContext';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';
import logo from '../images/upstate_logo.png';

export default function HomeLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.links);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };

  return (
    <div id="page">
      <CookieProvider content={cfg.cookie}>
        <Nav toplinks={toplinks}
             sitename={cfg.sitename}
             theme="bg-orange"
             ctx={props.ctx}
             currentpath={props.currentpath}
             localizerfn={localized} />
        <Banner title={cfg.banner.title} subtitle={cfg.banner.subtitle} text={cfg.banner.text} theme={cfg.banner.theme} />
        <div id="main">
          <StudyDashboard studies={props.studies} studyIntro={cfg.studyList} localizerfn={localized} />
          {props.children}
        </div>
        <Footer src={footdata} localizerfn={localized} />
        <div className="hpad-10p">
          <img src={logo} alt="Upstate Global Health" className="w-200 py-16" />
        </div>
      </CookieProvider>
    </div>
  );
};
